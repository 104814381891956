import React from "react"

// Component imports
import Header from "./Header"
import Footer from "./Footer"

const Layout: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  return (
    <div
      {...props}
      className="font-montserrat flex flex-col min-h-screen text-white bg-gray-800"
    >
      <Header />
      <main className="flex flex-col flex-1">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
