import React from "react"

import { graphql, useStaticQuery, Link } from "gatsby"

// Component imports
import Text from "./Text"
import QuickLink from "../components/QuickLink"
import Image from "gatsby-image"

// Static assets

import { ReactComponent as Twitter } from "../images/icons/twitter-brands.svg"
import { ReactComponent as Instagram } from "../images/icons/instagram-brands.svg"

const Footer: React.FunctionComponent = () => {
  // Queries

  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "footer_logo.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const SocialMedia = ({
    url,
    children,
    icon,
  }: {
    url: string
    children: string
    icon: any
  }) => {
    const Icon = icon

    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        className="flex items-center justify-center w-12 h-12 transition-all duration-300 ease-out transform bg-gray-800 rounded-full focus:outline-none focus:bg-gray-700 hover:bg-gray-700 focus:-translate-y-1 hover:-translate-y-1"
      >
        <span className="sr-only">{children}</span>
        <Icon className="w-6 h-6" />
      </a>
    )
  }

  return (
    <footer className="relative overflow-hidden">
      <div className="container relative z-10 p-6 pt-24 mx-auto md:p-10 xxl:pt-32">
        <div className="flex flex-col md:items-center md:justify-between lg:flex-row">
          {/* Buttons */}
          <div className="grid items-center justify-center gap-4 mb-6 lg:hidden">
            <QuickLink url="/privacy-policy">privacy policy</QuickLink>
            <QuickLink url="/tos">tos</QuickLink>
          </div>
          {/* Social media */}
          <div className="flex justify-center order-2 mb-6 space-x-4 lg:hidden">
            <SocialMedia
              url="https://instagram.com/krakennotify"
              icon={Instagram}
            >
              Instagram
            </SocialMedia>
            <SocialMedia url="https://twitter.com/krakennotify" icon={Twitter}>
              Twitter
            </SocialMedia>
          </div>

          {/* Center */}
          <div className="hidden lg:flex lg:order-2 lg:items-center lg:space-x-8">
            <div className="flex flex-col items-center justify-center">
              <SocialMedia
                url="https://instagram.com/krakennotify"
                icon={Instagram}
              >
                Instagram
              </SocialMedia>
              <Link
                to="/tos"
                className="block w-32 p-4 mt-4 text-xs text-center duration-300 ease-out transform bg-gray-800 border-2 border-red-600 rounded-full hover:-translate-y-1 focus:-translate-y-1 focus:outline-none hover:bg-gray-700 focus:bg-gray-700"
              >
                tos
              </Link>
            </div>
            <div className="order-2">
              <Image
                fluid={data.logo.childImageSharp.fluid}
                className="w-32"
                alt="Kraken Notify's Logo"
              />
            </div>
            <div className="flex flex-col items-center justify-center order-3">
              <SocialMedia
                url="https://twitter.com/krakennotify"
                icon={Twitter}
              >
                Twitter
              </SocialMedia>
              <Link
                to="/privacy-policy"
                className="block w-32 p-4 mt-4 text-xs text-center duration-300 ease-out transform bg-gray-800 border-2 border-red-600 rounded-full hover:-translate-y-1 focus:-translate-y-1 focus:outline-none hover:bg-gray-700 focus:bg-gray-700"
              >
                privacy policy
              </Link>
            </div>
          </div>

          {/* Copyright */}
          <div className="order-last lg:order-first">
            <Text className="text-sm font-thin text-center uppercase md:text-left">
              Copyright © 2020. <br className="md:hidden lg:block" /> KRAKEN
              NOTIFY LTD. <br className="md:hidden lg:block" /> All rights
              reserved.
            </Text>
          </div>

          {/* Message */}
          <div className="order-2 mb-6 lg:order-last">
            <Text className="text-sm font-thin text-center uppercase md:text-left">
              The best reselling <br className="md:hidden lg:block" /> network.
              Period.
            </Text>
          </div>

          {/* Logo */}
          <div className="order-first mb-6 lg:hidden">
            <Image
              fluid={data.logo.childImageSharp.fluid}
              alt="Kraken Notify's logo"
              className="w-32 mx-auto"
            />
          </div>
        </div>
      </div>
      <div className="h-6 bg-red-500">&nbsp;</div>
    </footer>
  )
}

export default Footer
