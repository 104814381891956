import React from "react"

const Section: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className = "",
  children,
  ...props
}) => {
  return (
    <section
      {...props}
      className={`container flex flex-col mx-auto px-6 py-12 md:px-10 md:py-24 ${className}`}
    >
      {children}
    </section>
  )
}

export default Section
