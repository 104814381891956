import React, { useEffect, useState } from "react"

// Component imports
import Text from "../components/Text"
import { ExternalLink, Link } from "./Button"

const Header: React.FC = () => {
  // State
  const [scroll, setScroll] = useState(false)

  // Determine scroll
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY !== 0) setScroll(true)
      else setScroll(false)
    })
  }, [])

  return (
    <header
      className={`fixed transition-all duration-300 ease-out top-0 left-0 z-50 flex items-center w-full ${
        scroll ? "p-2" : "p-4 xl:p-8"
      }`}
    >
      <div className="container mx-auto">
        <nav className="md:mx-auto md:px-12 md:py-6 lg:px-16 lg:py-8 xl:px-32 flex flex-wrap items-center justify-between w-full p-4 bg-gray-900 rounded-lg shadow-md">
          {/* Logo */}
          <Text
            tag="h1"
            className="md:w-auto tracking-10px w-full text-3xl font-bold text-center"
          >
            <span className="text-red-500">K</span>N
          </Text>

          {/* Buttons */}
          <div className="md:justify-end flex flex-row items-center justify-center flex-1 space-x-4">
            <Link url="/" variant="secondary">
              home
            </Link>
            <ExternalLink url="/login" variant="primary">
              login
            </ExternalLink>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
