import React from "react"

// Component imports
import { Link } from "gatsby"

const QuickLink = ({
  url,
  children,
  onClick,
}: {
  url: string
  children: string
  onClick?: Function
}) => (
  <Link
    onClick={onClick}
    to={url}
    className="block w-56 p-4 text-center duration-300 ease-out transform bg-gray-800 border-2 border-red-600 rounded-full hover:-translate-y-1 focus:-translate-y-1 focus:outline-none hover:bg-gray-700 focus:bg-gray-700"
  >
    {children}
  </Link>
)

export default QuickLink
