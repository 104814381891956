import React from "react"

interface Props {
  tag?: string
  styleTo?: string
  children: any
  className?: string
}

const Text = ({ children, styleTo, tag = "p", className = "" }: Props) => {
  const textStyles: { [index: string]: any } = {
    h1: "text-6xl font-thin tracking-10px font-bebasNeue",
    h2: "text-5xl md:text-6xl font-bebasNeue tracking-10px",
    h3: "text-2xl md:xl-text-3xl",
    h4: "text-xl md:text-2xl xl:text-3xl",
    h5: "text-lg md:text-xl xl:text-2xl",
    h6: "md:text-lg xl:text-xl",
    p: "leading-relaxed",
  }

  const index = !styleTo ? "p" : styleTo

  return React.createElement(
    tag,
    { className: `${textStyles[index]} ${className}` },
    children
  )
}

export default Text
